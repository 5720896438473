import { call, put, takeEvery, all } from 'redux-saga/effects';
import { message } from 'antd';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { fetchApiAuth, getCustomerIdFromCookie } from '../utils/api';

import {
    getHireContractsSuccess,
    getHireContractsFailure,

    getHireItemsSuccess,
    getHireItemsFailure,

    getHireContractsRequest,
    getHireContractSuccess,
    getHireContractFailure,

    orderAgainSuccess,
    orderAgainFailure,

    updateOrderSuccess,
    updateOrderFailure,

    setOffhireReminderSuccess,
    setOffhireReminderFailure,

    sendExchangeRequestSuccess,
    sendExchangeRequestFailure,

    getItemsForOffhireSuccess,
    getItemsForOffhireFailure,

    getExchangableItemsSuccess,
    getExchangableItemsFailure,

    createOffhireSuccess,
    createOffhireFailure,

    downloadOnHireReportSuccess,
    downloadOnHireReportFailure,

} from './orderActions';

const {
    GET_HIRE_CONTRACTS_REQUEST,
    GET_HIRE_ITEMS_REQUEST,
    GET_HIRE_CONTRACT_REQUEST,
    ORDER_AGAIN_REQUEST,
    UPDATE_ORDER_REQUEST,
    SET_OFFHIRE_REMINDER_REQUEST,
    SEND_EXCHANGE_REQUEST_REQUEST,
    GET_ITEMS_FOR_OFFHIRE_REQUEST,
    GET_EXCHANGABLE_ITEMS_REQUEST,
    CREATE_OFFHIRE_REQUEST,
    DOWNLOAD_ON_HIRE_REPORT_REQUEST,

} = require('./orderActions').constants;

function* getHireContracts({ payload }) {
    const customerId = getCustomerIdFromCookie();
    const params = {};
    if (payload.page) { params.page = payload.page; }
    if (payload.pageSize) { params.pageSize = payload.pageSize; }
    if (payload.search) { params.search = payload.search; }
    if (payload.status) { params.status = payload.status; }
    if (payload.siteId) { params.siteId = payload.siteId; }
    if (payload.sortBy) { params.sortBy = payload.sortBy; }
    if (payload.sortDirection) { params.sortDirection = payload.sortDirection; }
    if (customerId !== '') { params.customerId = customerId; }

    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'order/list-hire-contracts',
            params,
        });

        yield put(getHireContractsSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getHireContractsFailure(e.response ? e.response.data.message : e));
    }
}

function* getHireItems({ payload }) {
    try {
        // 241210 YUANKUI: FIX THX-112: Improve API Security to Prevent Data Leaking
        const customerId = getCustomerIdFromCookie() || 0;
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `order/${payload}/hire-items`,
            params: { customerId },
        });

        yield put(getHireItemsSuccess(response.data));

    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getHireItemsFailure(e.response ? e.response.data.message : e));
    }
}

function* getHireContract({ payload }) {
    try {
        // 241210 YUANKUI: FIX THX-112: Improve API Security to Prevent Data Leaking
        const customerId = getCustomerIdFromCookie() || 0;
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/order/${payload}/hire-contract-detail`,
            params: { customerId },
        });

        yield put(getHireContractSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getHireContractFailure(e.response ? e.response.data.message : e));
    }
}

function createOrderAgainParams(items) {
    let result = '';
    let i = 1;
    items.forEach((item) => {
        result += `${item.productId}:${item.quantity}${i < items.length ? ',' : ''}`;
        i++;
    });
    return result;
}

function* orderAgain({ payload }) {
    try {
        // 241210 YUANKUI: FIX THX-112: Improve API Security to Prevent Data Leaking
        const customerId = getCustomerIdFromCookie() || 0;
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `order/${payload}/order-again-items`,
            params: { customerId },
        });

        yield put(orderAgainSuccess(response));
        document.location = `/checkout?hire-contract-order-again=${createOrderAgainParams(response.data)}`;

    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(orderAgainFailure(e.response ? e.response.data.message : e));
    }
}

function* updateOrder({ payload }) {
    try {
        // 241210 YUANKUI: FIX THX-112: Improve API Security to Prevent Data Leaking
        const customerId = getCustomerIdFromCookie() || 0;
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/order/update',
            body: { ...payload, customerId },
        });

        yield put(updateOrderSuccess(response));
        message.success('Order updated successfully');
        if (payload.type && payload.type === 'hire' && payload.returnRecords) {
            yield put(getHireContractsRequest(payload));
        }
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(updateOrderFailure(e.response ? e.response.data.message : e));
    }
}

function* setOffhireReminder({ payload }) {
    try {
        // 241210 YUANKUI: payload already has customerId
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: '/order/set-offhire-reminder',
            body: payload,
        });

        yield put(setOffhireReminderSuccess(response));
        message.success('Off-Hire reminder has been set');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(setOffhireReminderFailure(e.response ? e.response.data.message : e));
    }
}

function* sendExchangeRequest({ payload }) {
    const wpUserId = document.cookie.replace(/(?:(?:^|.*;\s*)scorchWpUser\s*=\s*([^;]*).*$)|^.*$/, '$1');
    // 241210 YUANKUI: FIX THX-112: Improve API Security to Prevent Data Leaking
    const customerId = getCustomerIdFromCookie() || 0;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: 'order/request-exchange',
            body: { ...payload, wpUserId, customerId },
        });

        yield put(sendExchangeRequestSuccess(response));
        message.success('Your exchange request has been sent successfully.');
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(sendExchangeRequestFailure(e.response ? e.response.data.message : e));
    }
}

function* getItemsForOffhire({ payload }) {
    try {
        // 241210 YUANKUI: FIX THX-112: Improve API Security to Prevent Data Leaking
        const customerId = getCustomerIdFromCookie() || 0;
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `order/${payload}/items-for-offhire`,
            params: { customerId },
        });

        yield put(getItemsForOffhireSuccess(response.data));
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getItemsForOffhireFailure(e.response ? e.response.data.message : e));
    }
}

function* getExchangableItems({ payload }) {
    try {
        // 241210 YUANKUI: FIX THX-112: Improve API Security to Prevent Data Leaking
        const customerId = getCustomerIdFromCookie() || 0;
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `order/${payload}/exchangable-items`,
            params: { customerId },
        });

        yield put(getExchangableItemsSuccess(response.data));

    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(getExchangableItemsFailure(e.response ? e.response.data.message : e));
    }
}

function* createOffhire({ payload }) {
    try {
        // 241210 YUANKUI: FIX THX-112: Improve API Security to Prevent Data Leaking
        const customerId = getCustomerIdFromCookie() || 0;
        const response = yield call(fetchApiAuth, {
            method: 'POST',
            url: `order/${payload.offHireData.orderId}/create-off-hire`,
            body: { ...payload.offHireData, customerId },
        });

        yield put(createOffhireSuccess(response));
        yield put(getHireContractsRequest(payload.pageData));
        message.success({
            className: 'offhire-success-message',
            content: 'Your off-hire has been submitted. You will receive an email confirmation within 15 minutes. If you do not receive an email, please contact hire@thxuk.com.',
            duration: 7,
        });
    } catch (e) {
        message.error(e.response ? e.response.data.message : e);
        yield put(createOffhireFailure(e.response ? e.response.data.message : e));
    }
}

function* downloadOnHireReport({ payload }) {
    const customerId = getCustomerIdFromCookie() || 0;
    try {
        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: 'report/on-hire-report',
            params: {
                type: payload.type,
                customerId,
            },
            responseType: 'blob',
        });
        const ext = payload.type === 'excel' ? 'xlsx' : 'pdf';
        const fileSaverOptions = payload.type === 'excel' ? { type: 'application/vnd.ms-excel' } : { type: 'application/pdf' };
        const fileName = `thx-on-hire-report-${moment().format('DD-MM-YYYY')}.${ext}`;
        // eslint-disable-next-line no-undef
        const blob = new Blob([response.data], fileSaverOptions);
        saveAs(blob, fileName);
        yield put(downloadOnHireReportSuccess(response));
    } catch (e) {
        message.error('No data found for your request.');
        yield put(downloadOnHireReportFailure(e.response ? e.response.data.message : e));
    }
}

/**
 * Watch actions
 */
export default function* orderSaga() {
    yield all([
        takeEvery(GET_HIRE_CONTRACTS_REQUEST, getHireContracts),
        takeEvery(GET_HIRE_ITEMS_REQUEST, getHireItems),
        takeEvery(GET_HIRE_CONTRACT_REQUEST, getHireContract),
        takeEvery(ORDER_AGAIN_REQUEST, orderAgain),
        takeEvery(UPDATE_ORDER_REQUEST, updateOrder),
        takeEvery(SET_OFFHIRE_REMINDER_REQUEST, setOffhireReminder),
        takeEvery(SEND_EXCHANGE_REQUEST_REQUEST, sendExchangeRequest),
        takeEvery(GET_ITEMS_FOR_OFFHIRE_REQUEST, getItemsForOffhire),
        takeEvery(GET_EXCHANGABLE_ITEMS_REQUEST, getExchangableItems),
        takeEvery(CREATE_OFFHIRE_REQUEST, createOffhire),
        takeEvery(DOWNLOAD_ON_HIRE_REPORT_REQUEST, downloadOnHireReport),
    ]);
}
