import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row, Select, Tooltip, Form, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEllipsisH,
    faTimes,
    faTruck,
    faTruckLoading,
    faTable,
    faMapMarker,
} from '@fortawesome/free-solid-svg-icons';
import { faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { MinusOutlined, SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import ScorchTable from 'scorch-react-table';
import Main from '../../components/layout/Main';
import './style.scss';
import {
    getHireContractsRequest,
    getHireItemsRequest,
} from '../../core/order/orderActions';
import { getSettingsRequest } from '../../core/setting/settingActions';
import {
    getCustomerSitesRequest,
} from '../../core/account/accountActions';
import OrderActions from '../../components/OrderActions';
import HireDurationPreferences from '../../components/HireDurationPreferences';
import MapComponent from '../../components/MapComponent';
import OffHireReminder from '../../components/OffHireReminder';
import ExpandedRow from './ExpandedRow';

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
};

const HireContracts = ({ defaultView }) => {
    const query = useQuery();

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [currentSearch, setCurrentSearch] = useState(undefined);
    const [currentStatus, setCurrentStatus] = useState(undefined);
    const [currentSiteId, setCurrentSiteId] = useState(undefined);
    const [forceReminderModalToShow, setForceReminderModalToShow] = useState(false);
    const [useWebId, setUseWebId] = useState(null);
    const [useOrderId, setUseOrderId] = useState(null);
    const [sortBy, setSortBy] = useState(undefined);
    const [sortDirection, setSortDirection] = useState(undefined);

    const [selectedAction, setSelectedAction] = useState('none');
    const [selectedRecord, setSelectedRecord] = useState({});

    const [customExpandedRowKeys, customSetExpandedRowKeys] = useState([]);
    const [viewType, setViewType] = useState(defaultView || 'table');

    const dispatch = useDispatch();

    const { hireContracts, isFetchingHireContracts } = useSelector(state => state.order);
    const { settings } = useSelector(state => state.setting);
    const { hireDurationPreferences } = useSelector(state => state.user);
    const { customerSites } = useSelector(state => state.account);

    const getOnHireIconColour = (record) => {
        const { okay, good } = hireDurationPreferences;

        const currentDate = moment();
        const onHireDateMoment = moment(record.onHireDate, 'DD-MM-Y');

        if (onHireDateMoment.isSameOrAfter(currentDate.subtract(+good, 'months'))) {
            // good
            return '#63eb84';
        }
        if (onHireDateMoment.isSameOrAfter(currentDate.subtract(+okay, 'months'))) {
            // ok
            return '#ffc917';
        }
        // bad
        return '#da0812';

    };

    const getIconForStatus = (record) => {

        let faIcon = '';

        switch (record.status) {
        case 'Processing':
            faIcon = faEllipsisH;
            break;

        case 'Scheduled':
            faIcon = faTruckLoading;
            break;

        case 'On Hire':
            return <div className="on-hire-icon" style={{ backgroundColor: getOnHireIconColour(record) }} />;

        case 'Pending Collection':
        case 'Pending Partial Collection':
            faIcon = faTruck;
            break;

        case 'Off Hired':
        case 'Completed':
            faIcon = faSquareCheck;
            break;

        case 'Cancelled':
            faIcon = faTimes;
            break;

        default:
            return '';
        }
        return <div className="on-hire-icon"><FontAwesomeIcon icon={faIcon} /></div>;

    };

    const handlePageChange = (nextPage, size) => {
        const siteId = query.get('site') ?? null;
        const params = {
            page: nextPage,
            pageSize: size,
            search: currentSearch,
            status: currentStatus,
            siteId: currentSiteId,
            sortBy,
            sortDirection,
        };
        if (siteId) {
            params.search = siteId;
            params.site = siteId;
            params.siteId = siteId;
        }
        dispatch(getHireContractsRequest(params));
        setPageSize(size);
        setCurrentPage(nextPage);
    };

    const handleResetFilters = () => {
        dispatch(getHireContractsRequest({
            page: null,
            pageSize,
            sortBy,
            sortDirection,
        }));
        setCurrentSearch(undefined);
        setCurrentStatus(undefined);
        setCurrentSiteId(undefined);
        setCurrentPage(1);
    };

    const handleFilter = (e) => {
        setViewType('table');
        let filterStatus = e.status || currentStatus;
        let filterSiteId = e.siteId || currentSiteId;

        const data = {
            page: null,
            search: currentSearch,
            status: filterStatus,
            siteId: filterSiteId,
            sortBy,
            sortDirection,
            pageSize,
        };

        if (e.all) {
            data.status = undefined;
            data.siteId = undefined;
            filterStatus = undefined;
            filterSiteId = undefined;
        }

        dispatch(getHireContractsRequest(data));
        setCurrentStatus(filterStatus);
        setCurrentSiteId(filterSiteId);
        setCurrentPage(1);
    };

    const handleSearch = (e) => {
        setViewType('table');
        const filterSearch = e === '' ? undefined : e;

        const data = {
            page: null,
            search: filterSearch,
            status: currentStatus,
            siteId: currentSiteId,
            sortBy,
            sortDirection,
            pageSize,
        };

        dispatch(getHireContractsRequest(data));
        setCurrentSearch(filterSearch);
        setCurrentPage(1);
    };

    const handleSort = (paginationParam, e) => {
        setViewType('table');
        let pagination = paginationParam;
        if (!pagination) {
            pagination = {
                pageSize,
                current: currentPage,
            };
        }

        if (pagination.current !== currentPage) {
            handlePageChange(pagination.current, pagination.pageSize);
        } else {
            dispatch(getHireContractsRequest({
                page: 1,
                sortBy: e.order ? e.columnKey : null,
                sortDirection: e.order,
                search: currentSearch,
                status: currentStatus,
                siteId: currentSiteId,
                pageSize: pagination.pageSize,
            }));
            setSortBy(e.columnKey);
            setSortDirection(e.order);
            setPageSize(pagination.pageSize);
            setCurrentPage(1);
        }
    };

    const handleExpandRow = (record) => {
        dispatch(getHireItemsRequest(record.id));
    };

    const toggleRowExpand = (record, expanded) => {
        let newKeys = [];
        if (!expanded) {
            newKeys = [record.contractNo];
            handleExpandRow(record);
        }
        customSetExpandedRowKeys(newKeys);
    };

    const columns = [
        {
            title: 'CONTRACT NO.',
            dataIndex: 'contractNo',
            key: 'contractNo',
            width: '75px',
            sorter: true,
            render: (val, record) => (
                <Row justify="space-around" align="middle" key={`sub-row-${record.id}`}>
                    {customExpandedRowKeys.includes(record.contractNo)
                        ? <MinusOutlined onClick={() => toggleRowExpand(record, true)} style={{ color: 'red' }} />
                        : <SearchOutlined onClick={() => toggleRowExpand(record, false)} style={{ color: 'red' }} />}
                    <Link to={`/account/hire-contracts/${val}`}>{val}</Link>
                </Row>
            ),
        },
        {
            title: 'SITE ADDRESS',
            dataIndex: 'siteAddress',
            key: 'siteAddress',
            width: '75px',
            render: (val, record) => (
                <Tooltip title={val}>
                    {record.siteAddressLabel}
                </Tooltip>
            ),
            sorter: true,
        },
        {
            title: 'PO NUMBER',
            dataIndex: 'poNumber',
            key: 'poNumber',
            width: '75px',
            sorter: true,
        },
        {
            title: 'CONTACT',
            dataIndex: 'customerContact',
            key: 'customerContact',
            width: '75px',
            sorter: true,
        },
        {
            title: 'ON-HIRE',
            dataIndex: 'onHireDate',
            key: 'onHireDate',
            width: '75px',
            sorter: true,
        },
        {
            title: 'OFF-HIRE',
            dataIndex: 'offHireDate',
            key: 'offHireDate',
            width: '75px',
            sorter: true,
        },
        {
            title: 'OFF-HIRE REF',
            dataIndex: 'offHireRef',
            key: 'offHireRef',
            width: '75px',
            sorter: true,
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            width: '75px',
            align: 'center',
            className: 'status-column',
            render: (val, record) => (
                <Tooltip title={val}>
                    {getIconForStatus(record)}
                </Tooltip>
            ),
            sorter: true,
        },
        {
            title: 'ACTIONS',
            key: 'actions',
            width: '75px',
            render: (_, record) => (
                <div className="action-select-container">
                    <Select
                        size="small"
                        className="action-select"
                        popupMatchSelectWidth={false}
                        placement="bottomRight"
                        onChange={(value) => {
                            setSelectedAction(value);
                            setSelectedRecord(record);
                            if (value === 'off-hire-reminder') {
                                setUseWebId(record.contractNo);
                                setUseOrderId(record.id);
                                setForceReminderModalToShow(true);
                            }

                        }}
                        value="Action"
                        defaultValue="Action">
                        <Select.Option className="action-select-item" value="order-again">Order Again</Select.Option>
                        {record.status === 'On Hire' && (
                            <>
                                {settings.change_po_number_enabled && <Select.Option className="action-select-item" value="change-po-number">Change PO Number</Select.Option>}
                                <Select.Option
                                    data-order-id={record.id}
                                    data-web-id={record.contractNo}
                                    className="action-select-item"
                                    value="off-hire-reminder">
                                    Set Off-Hire Reminder
                                </Select.Option>
                                <Select.Option className="action-select-item" value="request-exchange">Request Exchange</Select.Option>
                            </>
                        )}
                        {['On Hire', 'Pending Partial Collection'].includes(record.status) && (
                            <Select.Option className="action-select-item" value="off-hire">Off-Hire</Select.Option>
                        )}
                    </Select>
                </div>
            ),
        },

    ];

    const renderExpandedRow = useCallback((record) => (
        <ExpandedRow
            settings={settings}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            record={record}
            setSelectedRecord={setSelectedRecord} />
    ), [settings, selectedAction, setSelectedAction, setSelectedRecord]);

    const filterOptions = {
        status: {
            options:
            [
                {
                    label: 'Pending',
                    value: 'D',
                },
                {
                    label: 'Processing',
                    value: 'Processing',
                },
                {
                    label: 'Scheduled',
                    value: 'Scheduled',
                },
                {
                    label: 'On Hire',
                    value: 'O',
                },
                {
                    label: 'Pending Collection',
                    value: 'Pending Collection',
                },
                {
                    label: 'Off Hired',
                    value: 'P',
                },
                {
                    label: 'Completed',
                    value: 'C',
                },
                {
                    label: 'Cancelled',
                    value: 'X',
                },
            ],
        },
    };

    const sortOptions = [
        {
            label: 'On-hire-date',
            value: 'onHireDate',
        },
        {
            label: 'Contract number',
            value: 'contractNo',
        },
        {
            label: 'PO number',
            value: 'poNumber',
        },
        {
            label: 'Order contact',
            value: 'customerContact',
        },
        {
            label: 'Site address',
            value: 'siteAddress',
        },
        {
            label: 'Off-hire date',
            value: 'offHireDate',
        },
    ];

    useEffect(() => {
        const siteId = query.get('site') ?? null;
        const params = { page: null };
        if (siteId) {
            params.search = siteId;
            params.site = siteId;
            params.siteId = siteId;
        }

        dispatch(getSettingsRequest());
        dispatch(getCustomerSitesRequest());
        dispatch(getHireContractsRequest(params));
    }, []);

    return (
        <Main
            title="Hire Contracts"
            afterTitle={(
                <div className="hire-contracts-view-button-container">
                    <span>VIEW</span>
                    <span>|</span>
                    <span style={{ cursor: 'pointer' }} onClick={() => setViewType('table')}>
                        <FontAwesomeIcon icon={faTable} style={{ color: viewType === 'table' ? '#da0812' : '#4a4a4a' }} />
                    </span>
                    <span style={{ cursor: 'pointer' }} onClick={() => setViewType('map')}>
                        <FontAwesomeIcon icon={faMapMarker} style={{ color: viewType === 'map' ? '#da0812' : '#4a4a4a' }} />
                    </span>
                </div>
            )}
            headerRightColumn={(
                <div className="hire-contract-filters-container">
                    <Form>
                        <Row align="middle" justify="space-between">
                            <Col span={12}>
                                <Form.Item name="sort" label="Sort by">
                                    <Select
                                        defaultValue="onHireDate"
                                        popupMatchSelectWidth={false}
                                        placement="bottomRight"
                                        onChange={(e) => handleSort(null, { columnKey: e, order: 'desc' })}>
                                        {sortOptions.map(o => <Select.Option key="option" value={o.value}>{o.label}</Select.Option>)}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="filter" label="Filter by">
                                    <Select
                                        defaultValue="all"
                                        popupMatchSelectWidth={false}
                                        placement="bottomRight"
                                        onChange={(e, g) => handleFilter({ [g.key]: e })}>
                                        <Select.Option key="all" value="all">All</Select.Option>
                                        <Select.OptGroup label="Status">
                                            {filterOptions.status.options.map(o => <Select.Option key="status" value={o.value}>{o.label}</Select.Option>)}
                                        </Select.OptGroup>
                                        <Select.OptGroup label="Sites">
                                            {customerSites && customerSites.map(o => <Select.Option key="siteId" value={o.ADR_ID}>{o.ADR_NAME}</Select.Option>)}
                                        </Select.OptGroup>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}>
            <div className="hire-contracts-container">
                <div className="search-hire-durations-container">
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Form>
                                <Form.Item name="search" style={{ marginBottom: 0 }}>
                                    <Input.Search
                                        placeholder="input search text"
                                        enterButton="Search"
                                        allowClear
                                        onSearch={(e) => handleSearch(e)} />
                                </Form.Item>
                            </Form>
                        </Col>
                        <HireDurationPreferences />
                    </Row>
                </div>
                {viewType === 'table' ? (
                    <ScorchTable
                        columns={columns}
                        dataSource={(hireContracts && hireContracts.data) || []}
                        pagination={{
                            current: currentPage,
                            pageSize,
                            defaultPageSize: 10,
                            total: hireContracts?.total,
                            size: 'large',
                            position: ['bottomCenter'],
                        }}
                        onSortChange={(pagination, e) => handleSort(pagination, e)}
                        filtersSize="small"
                        onFilter={(e) => handleFilter(e)}
                        onResetFilters={handleResetFilters}
                        loading={isFetchingHireContracts}
                        expandableRows
                        onExpandRow={(record) => handleExpandRow(record)}
                        customExpandedRowKeys={customExpandedRowKeys}
                        customSetExpandedRowKeys={customSetExpandedRowKeys}
                        showExpandColumn={false}
                        expandedRowRender={renderExpandedRow}
                        expandedRowIdentifier="contractNo" />
                ) : (
                    <MapComponent />
                )}
            </div>
            <OffHireReminder
                forceModalToShow={forceReminderModalToShow}
                modalOnHide={() => setForceReminderModalToShow(false)}
                useWebId={useWebId}
                useOrderId={useOrderId} />
            <OrderActions
                setSelectedAction={setSelectedAction}
                selectedAction={selectedAction}
                setSelectedRecord={setSelectedRecord}
                selectedRecord={selectedRecord}
                pageData={{
                    page: currentPage,
                    pageSize,
                    search: currentSearch,
                    status: currentStatus,
                    sortBy,
                    sortDirection,
                    currentPage,
                    siteId: currentSiteId,
                }} />
        </Main>
    );

};

HireContracts.propTypes = {
    defaultView: PropTypes.string,
};

HireContracts.defaultProps = {
    defaultView: 'table',
};

export default HireContracts;
